<template>
    <Table
        :isLoading="isLoading"
        :columnConfig="tableColumnConfig"
        :rows="tableShowData"
        :sortDefault="{ sort: 'desc', sortBy: 'fybPersistency' }"
        :excelConfig="excelConfig"
        :toolbox="['columnSetting', 'excel', 'zooming']"
        :isUseCard="false"
        :isFocusRow="true"
        :descriptions="descriptions"
        :isFixedHeader="true"
    />
</template>

<script>
import _ from 'lodash'
import Table from '@/components/Table.vue'
import {
    annualColumns,
    annualDescriptions
} from '@/assets/javascripts/report/setting'

export default {
    name: 'AnnualTable',
    components: {
        Table
    },
    props: {
        listData: {
            type: Array,
            default: function () {
                return []
            }
        },
        filterLog: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: true
        },
        emptyMessage: {
            type: String,
            default: ''
        },
        infos: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    methods: {
        transformData: function (rows) {
            /* ***
             * 序列號依照繼續率業績排，套用篩選要重編號、排序不重編號。
             * 故不能進Table元件才依照繼續率排序，要直接改寫資料源的排序。
             *** */
            return (
                _.orderBy(rows, 'fybPersistency', 'desc')
                    // 序列編號
                    .map((row, index) => {
                        row.serialNumber = index + 1
                        return row
                    })
                    // 業績後端已經四捨五入，前端保險起見再一次
                    .map((row) => {
                        row.fybPersistency = this.$roundX(row.fybPersistency, 0)
                        return row
                    })
            )
        }
    },
    computed: {
        tableShowData: function () {
            if (this.listData.length === 0) return []
            let dataset = _.cloneDeep(this.listData)
            dataset = this.transformData(dataset)
            return dataset
        },
        tableColumnConfig: function () {
            const columns = _.cloneDeep(annualColumns)
            if (this.filterLog.dataType?.value === '1')
                columns.fybPersistency.label = '累積受理FYC'
            else columns.fybPersistency.label = '累積核實FYC'

            if (this.filterLog.relationMode?.value === 'cmGroup')
                columns.recommenderName.label = '輔導人'
            else columns.recommenderName.label = '推薦人'

            if (this.filterLog.role?.value === 'ceo') {
                // CEO不用顯示業績來源
                delete columns.source
                // 調整欄寬
                columns.bgName.width += 2
                columns.name.width += 3
                columns.salesTitle.width += 4
                columns.fybPersistency.width += 5
            }
            return columns
        },
        descriptions: function () {
            return annualDescriptions(
                this.filterLog.dataType?.value,
                this.filterLog.role?.value
            )
        },
        hasData: function () {
            return this.tableShowData.length > 0 || this.isLoading
        },
        excelFileName: function () {
            const name =
                this.filterLog.bgCode?.label || this.filterLog.manCode?.label
            return `系統制繼續率發放資格查詢_${name}`
        },
        excelConfig: function () {
            return {
                fileName: this.excelFileName,
                filters: this.filterLog,
                descriptions: this.descriptions,
                infos: this.infos
            }
        }
    },
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped></style>
